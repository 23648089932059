import TeilnahmebedingungenFacebook from 'pages/TeilnahmebedingungenFacebook';
import TeilnahmebedingungenInsta from 'pages/TeilnahmebedingungenInsta';
import React from 'react';
import { Redirect } from 'react-router';

const Home = React.lazy(() => import('../pages/Home'));

const AboutMalbuner = React.lazy(() => import('../pages/AboutMalbuner'));
const History = React.lazy(() => import('../pages/AboutMalbuner/History'));
const LaedenUndAktionen = React.lazy(() =>
  import('../pages/AboutMalbuner/LaedenUndAktionen'),
);
const Aktionen = React.lazy(() => import('../pages/AboutMalbuner/Aktionen'));
const TVSpots = React.lazy(() => import('../pages/AboutMalbuner/TVSpots'));

const SocialMedia = React.lazy(() => import('../pages/News/SocialMedia'));
const Sponsorings = React.lazy(() => import('../pages/News/Sponsorings'));
const SponsoringsFormular = React.lazy(() =>
  import('../pages/News/SponsoringsFormular'),
);
const Events = React.lazy(() => import('../pages/News/Events'));
const PartysticksNeuesDesign = React.lazy(() =>
  import('../pages/News/PartysticksNeuesDesign'),
);

const CharcuterieSortiment = React.lazy(() =>
  import('../pages/Charcuterie/Sortiment'),
);

const CharcuterieSingle = React.lazy(() =>
  import('../pages/Charcuterie/CharcuterieSingle'),
);

const SnacksSortiment = React.lazy(() => import('../pages/Snacks/Sortiment'));
const SnacksSingle = React.lazy(() => import('../pages/Snacks/SnacksSingle'));

const Vegan = React.lazy(() => import('../pages/Vegan'));
const VeganSingle = React.lazy(() => import('../pages/Vegan/VeganSingle'));

const ConvenienceSortiment = React.lazy(() =>
  import('../pages/Convenience/Sortiment'),
);
const ConvenienceSingle = React.lazy(() =>
  import('../pages/Convenience/ConvenienceSingle'),
);

const Nachhaltigkeit = React.lazy(() => import('../pages/Nachhaltigkeit'));
const ShopLanding = React.lazy(() => import('../pages/ShopLanding'));

const FAQ = React.lazy(() => import('../pages/faq'));
const Kontakt = React.lazy(() => import('../pages/Kontakt'));

const Spotify = React.lazy(() => import('../pages/Spotify'));

const Setup = React.lazy(() => import('../pages/_Setup'));
const Impressum = React.lazy(() => import('../pages/Impressum'));
const Datenschutz = React.lazy(() => import('../pages/Datenschutz'));
const Teilnahmebedingungen = React.lazy(() =>
  import('../pages/Teilnahmebedingungen'),
);

const Error404 = React.lazy(() => import('../pages/Error404'));

export const routes = [
  // {
  //   exact: true,
  //   path: '/gewinnspiel',
  //   component: () => <Redirect to="/25-jubilaeum-gewinnspiel" />,
  // },

  {
    exact: true,
    path: '/',
    component: Home,
  },
  // ÜBER MALBUNER
  {
    exact: true,
    path: '/ueber-malbuner',
    component: AboutMalbuner,
  },

  {
    exact: true,
    path: '/malbuner-geschichte',
    component: History,
  },
  {
    exact: true,
    path: '/tv-spots',
    component: TVSpots,
  },
  {
    exact: true,
    path: '/laeden-und-aktionen',
    component: LaedenUndAktionen,
  },
  {
    exact: true,
    path: '/aktionen',
    component: Aktionen,
  },
  {
    exact: true,
    path: '/aktionen/:standort',
    component: Aktionen,
  },

  // News
  {
    exact: true,
    path: '/news/partysticks-neues-design',
    component: PartysticksNeuesDesign,
  },
  {
    exact: true,
    path: '/social-media',
    component: SocialMedia,
  },
  {
    exact: true,
    path: '/sponsorings',
    component: Sponsorings,
  },
  {
    exact: true,
    path: '/sponsorings/anfrage',
    component: SponsoringsFormular,
  },
  {
    exact: true,
    path: '/events',
    component: Events,
  },

  // CHARCUTERIE
  {
    exact: true,
    path: '/charcuterie',
    component: () => <Redirect to="/charcuterie/sortiment" />,
  },
  {
    exact: true,
    path: '/charcuterie/sortiment',
    component: CharcuterieSortiment,
  },
  {
    exact: true,
    path: '/charcuterie/brathaxn',
    component: () => <Redirect to="/charcuterie/brathaxen" />,
  },
  {
    exact: true,
    path: '/charcuterie/:slug',
    component: CharcuterieSingle,
  },

  // SNACKS
  {
    exact: true,
    path: '/snacks',
    component: () => <Redirect to="/snacks/sortiment" />,
  },
  {
    exact: true,
    path: '/snacks/sortiment',
    component: SnacksSortiment,
  },

  // Snacks Redirects
  {
    exact: true,
    path: '/snacks/partysticks-poulet',
    component: () => <Redirect to="/snacks/partysticks-chicken" />,
  },
  {
    exact: true,
    path: '/snacks/party-salamini-poulet',
    component: () => <Redirect to="/snacks/party-salamini-chicken" />,
  },

  {
    exact: true,
    path: '/snacks/:slug',
    component: SnacksSingle,
  },

  // CONVENIENCE
  {
    exact: true,
    path: '/convenience/sortiment',
    component: ConvenienceSortiment,
  },
  {
    exact: true,
    path: '/convenience/:slug',
    component: ConvenienceSingle,
  },

  // VEGGIE
  {
    exact: true,
    path: '/vegan',
    component: Vegan,
  },
  {
    exact: true,
    path: '/vegan/:slug',
    component: VeganSingle,
  },

  // NACHHALTIGKEIT
  {
    exact: true,
    path: '/nachhaltigkeit/',
    component: Nachhaltigkeit,
  },

  // SONSTIGES
  {
    exact: true,
    path: '/faq',
    component: FAQ,
  },
  {
    exact: true,
    path: '/kontakt',
    component: Kontakt,
  },
  {
    exact: true,
    path: '/setup',
    component: Setup,
  },
  {
    exact: true,
    path: '/spotify',
    component: Spotify,
  },
  // {
  //   exact: true,
  //   path: '/auszeichnungen',
  //   component: Auszeichnungen,
  // },
  {
    exact: true,
    path: '/impressum',
    component: Impressum,
  },
  {
    exact: true,
    path: '/datenschutz',
    component: Datenschutz,
  },
  {
    exact: true,
    path: '/teilnahmebedingungen',
    component: Teilnahmebedingungen,
  },
  {
    exact: true,
    path: '/teilnahmebedingungen-ig',
    component: TeilnahmebedingungenInsta,
  },
  {
    exact: true,
    path: '/teilnahmebedingungen-fb',
    component: TeilnahmebedingungenFacebook,
  },

  {
    exact: false,
    path: '/onlineshop',
    component: ShopLanding,
  },
  // {
  //   exact: false,
  //   path: '/25-jubilaeum-gewinnspiel',
  //   component: PartySticksLanding,
  // },
  // {
  //   exact: false,
  //   path: '/gewinnspiel-hcd',
  //   component: GewinnspielHCD,
  // },

  // ERROR
  {
    exact: false,
    path: '/',
    component: Error404,
  },
];
